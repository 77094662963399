#root>div {
    background-image: url('../img/list-1.jpg');
}

.players-container {
    min-height: 80vh;
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
    padding: 20px;
    background-color: rgba(15, 15, 15, 0.9);
    color: #e0e0e0;
    border-radius: 10px;
}

.search-input {
    padding: 10px;
    width: 100%;
    margin-bottom: 20px;
    border-radius: 5px;
    border: 1px solid #333;
    background-color: #222;
    color: #fff;
    font-size: 16px;
}

.players-list {
    list-style: none;
    padding: 0;
    margin: 0;
    max-height: 400px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: center; /* Centra los items de la lista */
}

.player-item {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    background-color: rgba(45, 45, 45, 0.9);
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between; /* Distribuye los elementos */
    transition: background-color 0.3s ease;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
}

.player-rank {
    font-size: 18px;
    font-weight: bold;
    color: #c45c65;
    position: relative;
}

.player-name {
    flex-grow: 1;
    margin-left: 75px;
    color: #d45252;
    text-decoration: none;
    font-size: 18px;
    transition: color 0.3s ease;
}

.player-name:hover {
    color: #ffffff;
}

.player-icons {
    display: flex;
    margin-left: 10px;
}

.player-icon {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    object-fit: cover;
    margin-left: 5px; /* Espacio entre los íconos */
}

.player-date {
    margin-left: 20px;
    font-size: 14px;
    color: #b0b0b0;
    text-align: right;
}

.player-item:hover {
    background-color: rgba(60, 60, 60, 0.8);
}

#root > div > div > input {
    margin-top: 10px;
}

@media (max-width: 768px) {
    .player-item {
        justify-content: center; /* Mantén el contenido centrado en móviles */
        flex-direction: column; /* Alinea los elementos verticalmente en pantallas pequeñas */
        text-align: center;
    }

    .player-rank {
        left: 0;
    }

    .player-name {
        margin-left: 0;
    }

    .player-icons {
        margin-left: 0;
        justify-content: center;
    }

    .player-date {
        margin-left: 0;
        margin-top: 5px;
        text-align: center;
    }
}
