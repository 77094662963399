/* Reset */
body,
html {
  height: 100%;
  margin: 0;
  background-color: black;
}

/* General Styles */
.bg-dark {
  opacity: 0.9;
}

.background-container {
  background-image: url('./img/hunt-1.webp');
  background-size: cover;
  background-position: center;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}


.navbar-brand img {
  width: 100px;
  height: auto;
}

.logo-text {
  font-size: 1.8rem;
  font-weight: bold;
  color: #fff;
  text-align: left;
}

.content-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 70px;
}

.content-container h1 {
  font-size: 4rem;
  font-weight: bold;
  letter-spacing: 2px;
}

.content-container p {
  font-size: 1.5rem;
}

.btn-primary {
  background-color: #5e2f96;
  border-color: #5e2f96;
}

.btn-primary:hover {
  background-color: #4b2480;
  border-color: #4b2480;
}

.navbar-nav {
  margin-right: 150px;
}

.navbar-nav li a {
  font-size: 18px;
  margin: 0 15px;
}

.section {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center;
  color: white;
}

.bg-image-1 {
  background-image: url('./img/hunt-1.webp');
}

.bg-image-2 {
  background-image: url('./img/hunt-7.png');
}

.bg-image-3 {
  background-image: url('./img/hunt-4.jpeg');
}

.bg-image-4 {
  background-image: url('./img/hunt-5.webp');
}

.content {
  text-align: center;
  color: white;
  margin-top: 50px;
}

/* Grid Layout for Section 2 */
.grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  /* Dos columnas iguales */
  grid-gap: 20px;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.5);
  /* Fondo transparente oscuro */
  border-radius: 10px;
  /* Bordes redondeados */
}

.grid-item {
  display: flex;
  /* Para asegurar que tanto el contenido como las imágenes se mantengan en bloque */
  justify-content: center;
  align-items: center;
  text-align: center;
}

/* Aseguramos que las imágenes y el texto estén uno al lado del otro */
.grid-item img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.grid-item.content {
  text-align: left;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 768px) {
  .grid-container {
    grid-template-columns: 1fr;
  }
}

@media (min-width: 769px) {
  .navbar-brand {
    margin-left: 50px;
  }

  .logo-text {
    margin-left: 10px;
  }
}


#aboutus>div>h1 {
  font-size: 3rem;
  margin-bottom: 20px;
}

#aboutus>div>p {
  font-size: 1.3rem;
  line-height: 1.8;
  max-width: 800px;
}

.footer {
  background-color: rgba(0, 0, 0, 0.938);
  position: relative;
  bottom: 0;
  width: 100%;
}

.footer p {
  margin: 0;
  font-size: 14px;
}

#report_form {
  background-image: url('./img/hunt-showdown-1896.jpg');
  height: 500px;
}