#background-video {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
    pointer-events: none;
}

.section {
    position: relative;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    z-index: 1;
    margin-bottom: 100px;
    color: white;
}

.footer {
    position: relative;
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    text-align: center;
    padding: 20px;
    height: 150px;
    z-index: 2;
}

.section:last-of-type {
    margin-bottom: 100px;
}