body.report-form-bg {
    background-image: url('../img/hunt-showdown-1896.jpg');
    background-size: auto;
    background-position: center;
    background-repeat: no-repeat;
    margin: 0;
    padding: 0;
}


.form-container {
    max-width: 800px;
    margin: auto;
    padding: 40px;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    margin-top: 80px;
    margin-bottom: 80px;
    margin-bottom: 150px;
}

.form-container {
    max-width: 800px;
    margin: auto;
    padding: 40px;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    margin-top: 80px;
    margin-bottom: 150px;
}

.spinner-container {
    display: flex;
    justify-content: center;
    margin-top: 40px;
}

.spinner {
    width: 150px;
    height: 150px;
    animation: spin 6s linear infinite;
}
